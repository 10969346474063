<template>
  <a-spin :spinning="ifSub">
    <a-form :form="form">
      <a-form-item label="确认人" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="['confirmPerson',{ rules: [{ required: true, message: '确认人不能为空' }] }]" placeholder="请输入确认人"></a-input>
      </a-form-item>
      <a-form-item label="确认结果" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input type="textarea" rows="4" v-decorator="['confirmResult']" placeholder="请输入确认结果"></a-input>
      </a-form-item>
      <!--<a-form-item label="确认人" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-tree-select
          v-decorator="[
                'confirmUser.id',
                {
                  validateTrigger: ['change'],
                  rules: [{
                    type: 'string',
                    required: true,
                    message: '请选择确认人',
                  }]
                }
              ]"
          treeDefaultExpandAll
          treeNodeFilterProp="title"
          style="width: 100%"
          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
          :treeData="orgUserTreeData"
          placeholder="请选择制定人"
        />
      </a-form-item>-->
    </a-form>
  </a-spin>
</template>
<script>
  import { damageReportFormVO } from './common/common'
  import SERVICE_URLS from '@/api/service.url'

  export default {
    name: 'confirmForm',
    props: {
      type: {
        type: String,
        default: 'add'
      }
    },
    data () {
      return {
        form: this.$form.createForm(this),
        labelCol: { span: 5 },
        wrapperCol: { span: 18 },
        ifSub: false,
        formItem: damageReportFormVO(),
        orgUserTreeData: [] //确认人下拉数据
      }
    },
    created () {
      // this.loadUserList()
    },
    computed: {
      currentUser () {
        return this.$store.getters.currentUser
      }
    },
    mounted () {
    },
    methods: {
      confirmPerson () {
        this.form.setFieldsValue({
          'confirmPerson': this.currentUser.name
        })
      },
      setVOFields (values) {
        // var confirmUserId = values.confirmUser.id.split('_')[2]
        this.formItem.confirmResult = values.confirmResult
        this.formItem.confirmPerson = values.confirmPerson
      },
      loadUserList () {
        this.$http(this, {
          url: SERVICE_URLS.organization.orgUserTree,
          data: {
            authPrefix: '',
            chooseType: 'RADIO'
          },
          noTips: true,
          success: (data) => {
            this.orgUserTreeData = data.body
            /*默认设置确定人*/
            this.$nextTick(() => {
              const managerUserKey = this.getSelectKey(this.orgUserTreeData, this.currentUser.id)
              if (managerUserKey) {
                this.form.setFieldsValue({
                  'confirmUser.id': managerUserKey
                })
              }
            })
          }
        })
      },
      confirm (id) {
        this.formItem.handleStatus = 'CONFIRM_COMPLETE'
        this.form.validateFields(
          (err, values) => {
            if (!err) {
              this.setVOFields(values)
              this.$http(this, {
                url: SERVICE_URLS.ticket.damageReport.update,
                params: { id: id },
                data: this.formItem,
                noTips: true,
                success: (data) => {
                  //清空表单数据
                  this.formItem = damageReportFormVO()
                  this.form.resetFields()
                  //添加成功，清空表单数据，并触发回调
                  this.$emit('confirmSuccess')
                },
                error: () => {
                  this.$emit('confirmError')
                }
              })
            }
          })
      },
      handleTypeChange (e) {
        this.handleType = e.target.value
      },
      getSelectKey (list, id) {
        var key
        for (let i = 0; i < list.length; i++) {
          const item = list[i]
          const tempKey = item.key
          if (tempKey.substring('USER_') !== -1 && Number(tempKey.split('_')[2]) === id) {
            key = tempKey
            return key
          } else if (item.children.length > 0) {
            key = this.getSelectKey(item.children, id)
          }
          if (key) return key
        }
      },
    }
  }
</script>

<style scoped></style>