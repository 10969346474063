<template>
  <a-spin :spinning="spinShow">
    <a-form :form="form">
      <a-form-item label="人员名称" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="['fromName', { rules: [{ required: true, message: '名称不能为空' }] }]" placeholder="请输入">
        </a-input>
      </a-form-item>
      <a-form-item label="人员电话" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="['fromPhone', { rules: [{ required: true,   pattern: new RegExp(/^[+-]?(0|([1-9]\d*))(\.\d+)?$/g, 'g'),
        message: '人员电话不能为空而且只能为数字' }] }]" placeholder="请输入">
        </a-input>
      </a-form-item>
      <a-form-item label="人员地址" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="['fromAddress', { rules: [{ required: true, message: '地址不能为空' }] }]" placeholder="请输入">
        </a-input>
      </a-form-item>
      <a-form-item label="上报时间"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-date-picker style="width: 100%" :showTime="{ format: 'YYYY-MM-DD HH:mm:ss' }"
                       format="YYYY-MM-DD HH:mm:ss"
                       v-decorator="['fromTime',{rules: [{ required: true, message: '上报时间不能为空' }]}]"
                       placeholder="请选择"></a-date-picker>
      </a-form-item>
      <a-form-item label="上报原因" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-textarea
          v-decorator="['reason',{ rules: [{ required: true, message: '来电原因不能为空' }] }]"
          placeholder="请输入上报原因"
          :autoSize="{ minRows: 4 }"
        ></a-textarea>
      </a-form-item>
      <a-form-item label="所属部门" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-tree-select
          v-decorator="['departmentId', {initialValue: defaultDeptId, rules: [{ required: true, message: '所属部门不能为空' }] }]"
          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
          :treeData="orgTree"
          allowClear
          placeholder="请选择，默认当前登录用户所在部门"
        ></a-tree-select>
      </a-form-item>
      <a-form-item label="故障类型" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-radio-group v-decorator="['damageType', {initialValue: '公共设施', rules: [{ required: true, message: '请选择故障类型' }] }]"
                       defaultValue="公共设施">
          <a-radio value="公共设施">
            公共设施
          </a-radio>
          <a-radio value="入户设施">
            入户设施
          </a-radio>
          <a-radio value="其他设施">
            其他设施
          </a-radio>
        </a-radio-group>
      </a-form-item>
            <a-form-item label="化粪池类型" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-radio-group  v-decorator="['containerType', {initialValue: 'JIWUCHI',rules: [{ required: true, message: '请选择类型' }] }]">
                <a-radio :value="spotTypeEnum.DANWENG.value">
                  单翁
                </a-radio>
                <a-radio :value="spotTypeEnum.SANGE.value">
                  一体三格化粪池
                </a-radio>
                <a-radio :value="spotTypeEnum.JIWUCHI.value">
                  集污池
                </a-radio>
                <a-radio :value="spotTypeEnum.LANI.value">
                  拉泥
                </a-radio>
                <a-radio :value="spotTypeEnum.GONGCE.value">
                  公厕
                </a-radio>
                <a-radio :value="spotTypeEnum.OTHER.value">
                  其他
                </a-radio>
              </a-radio-group>
            </a-form-item>
      <a-form-item label="上传图片" :label-col="labelCol" :wrapper-col="wrapperCol">
        <qiniu-image-upload
          ref="qiniuImageRef"
          @getImageData="getImageData"
          :document-dir-id="1"
          :multiSelection="true"
          type="edit"
          :items="formItem.images"
        ></qiniu-image-upload>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
  import { damageReportFormVO, reportTypeList } from './common/common'
  import SERVICE_URLS from '@/api/service.url'
  import QiniuImageUpload from '@/components/QiniuUpload/QiniuImageUpload'
  import { spotTypeEnum } from '@/constant/spot/spotType'

  export default {
    name: 'damageReportForm',
    props: {
      type: {
        type: String,
        default: 'edit'
      }
    },
    components: { QiniuImageUpload },
    data() {
      return {
        reportTypeList: reportTypeList(),
        labelCol: { span: 5 },
        wrapperCol: { span: 17 },
        spinShow: false,
        formItem: damageReportFormVO(),
        form: this.$form.createForm(this),
        orgTree: [],
        updateReportId: '', //修改上报内容的记录ID,
        defaultDeptId: null,
        spotTypeEnum: spotTypeEnum
      }
    },
    mounted() {
      this.getOrgTree()
      // this.form.setFieldsValue({
      //   'departmentId': this.currentDeptId().organizationId + '',
      //   'damageType': '公共设施'
      // })
      this.setDeptId()
      this.defaultDeptId = this.currentDeptId().organizationId + ''
    },
    created() {
    },
    methods: {
      setDeptId() {
        let deptId = null
        // 获取当前部门
        if (this.currentUser) {
          if (this.currentUser.organizationJobs) {
            if (this.currentUser.organizationJobs.length) {
              deptId = this.currentDeptId().organizationId + ''
            }
          }
        }
        if (deptId) {
          this.form.setFieldsValue({
            'departmentId': deptId
          })
        }
      },
      getOrgTree() {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.orgTree = data.body
          }
        })
      },
      setVOFields(values) {
        Object.assign(this.formItem, values)
      },
      setFields(values) {
        Object.assign(this.formItem, values.damageReportRecord)
        values.images.forEach((item) => {
          this.formItem.imageIds.push(item.id)
        })
        this.form.setFieldsValue({
          fromName: this.formItem.fromName,
          fromPhone: this.formItem.fromPhone,
          fromAddress: this.formItem.fromAddress,
          reason: this.formItem.reason,
          type: this.formItem.type,
          containerType: this.formItem.containerType,
          damageType: this.formItem.damageType,
          fromTime: this.formItem.fromTime,
          'departmentId': this.formItem.department.id + ''
        })
      },
      loadData(id) {
        this.updateReportId = id + ''
        this.spinShow = true
        this.$http(this, {
          url: SERVICE_URLS.ticket.damageReport.view,
          params: {
            id: id
          },
          noTips: true,
          success: (data) => {
            this.$nextTick(() => {
              this.setFields(data.body)
            })
            this.spinShow = false
          }
        })
      },
      submitEdit() {
        this.form.validateFields((err, values) => {
          if (!err) {
            if (typeof this.setVOFields !== 'undefined' && this.setVOFields instanceof Function) {
              this.setVOFields(values)
            }
            this.$http(this, {
              url: SERVICE_URLS.ticket.damageReport.updateReport,
              data: this.formItem,
              params: {
                id: this.updateReportId,
              },
              success: (data) => {
                this.$emit('editSuccess', data.body)
              },
            })
          } else {
            this.$emit('editError', 'formError')
          }
        })
      },
      submitAdd() {
        this.spinShow = true
        this.form.validateFields((err, values) => {
          if (!err) {
            this.setVOFields(values)
            this.$http(this, {
              url: SERVICE_URLS.ticket.damageReport.register,
              noTips: true,
              data: this.formItem,
              success: (data) => {
                this.formItem = damageReportFormVO()
                this.$emit('addSuccess')
                this.form.resetFields()
                this.spinShow = false
              },
              error: (data) => {
                this.$emit('addError')
                this.form.resetFields()
                this.spinShow = false
              }
            })
          } else {
            this.spinShow = false
          }
        })
      },
      /*上传图片回调方法*/
      getImageData(data) {
        this.formItem.imageIds = []
        data.forEach(item => {
          this.formItem.imageIds.push(item.id)
        })
      }
    }
  }
</script>

<style scoped></style>
