export const carRecordFormVO = function () {
  return {
    department: {
      id: null,
      name: '',
    },
    carInfo: {
      id: null,
      fullName: '',
    },
    managerUser: {
      id: null,
      name: '',
    },
    executeUser: {
      id: null,
      name: '',
    },
    planStartTime: '', //pc端计划开始时间
    planEndTime: '', //计划结束时间
    summary: '', //行车记录概要
    mileage: '', //行车公里
    status: '',
    recordTrail: {
      id: null,
      position: null,
      startLocation: '',
      endLocation: '',
    },
    type: 'GIVEN',
    instanceId: '',
    systemModule: ''
  }
}
